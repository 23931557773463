// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// https://sgrespadev.siemensgamesa.com

export const environment = {
    production: false,
    staging: false,
    siteUrls: {
        getBaseUrl: 'https://api.c4khy-siemensga1-d1-public.model-t.cc.commerce.ondemand.com',
        getBackendUrl: 'https://localhost:9002',
        getBackofficeUrl: 'https://backoffice.c4khy-siemensga1-d1-public.model-t.cc.commerce.ondemand.com/medias/',
        getPrefix: '/rest/v2',
        getSiteName: '/sgre',
        getUser: '/users/',
        smartEdit: '*.c4khy-siemensga1-d1-public.model-t.cc.commerce.ondemand.com:443'
    },
    msalConfigDetails: {
        clientId: 'b9be6c65-ec44-45ff-8309-4b61b6062bf5',
        authority: 'https://login.microsoftonline.com/0ad94219-6af5-474e-99d0-60f9188f3fce',
        redirectUrls: {
            envUrl: 'https://sgresitecoredev.siemensgamesa.com',
            smartEdit: 'https://jsapps.c4khy-siemensga1-d1-public.model-t.cc.commerce.ondemand.com',
            frontEndLocal: 'http://localhost:4200',
            backendLocal: 'https://localhost:9002'
        }
    },
    oAuthConfigDetails: {
        grant_type: 'custom',
        scope: 'extended',
        client_id: 'trusted_client',
        client_secret: 'secret',
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
